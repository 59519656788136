import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import ImageModal from 'components/ui/image-modal';
import { authorKamil as author } from 'data/authors';

import img from 'img/blog/updates/masking_data.png';

const Content = () => {
  return (
    <div>
      <p>
        From now, LiveSession gives an ability to suppress sensitive data. By adding a{' '}
        <strong>data-ls-disabled</strong> attribute to HTML element, the tracking code will
        anonymize element content.
      </p>
      <ImageModal src={img} alt="Private data" title="Hide sensitive data" />
      <p className="mt-3" />
    </div>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Masking sensitive data',
  url: '/blog/masking-sensitive-data/',
  description: 'Disable recording of sensitive data',
  author,
  img,
  date: '2019-04-29',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    group={frontmatter.group}
  />
);
